var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "history-label text-heading-2 font-weight-bold",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 最近登録・更新された物件 ")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    attrs: {
      "rounded": "",
      "small": "",
      "text": ""
    },
    on: {
      "mousedown": function mousedown($event) {
        return _vm.swipeLeft('customer');
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-arrow-left ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_vm.customerLoading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }) : _vm._e()], 1), _c('v-row', {
    ref: "contentCustomer",
    staticClass: "flex-nowrap overflow-x-hidden",
    attrs: {
      "id": "contentCustomer"
    }
  }, [_vm._l(_vm.customers, function (customer, index) {
    return _c('v-col', {
      key: "history-item-".concat(index),
      attrs: {
        "cols": "auto"
      },
      on: {
        "click": function click($event) {
          return _vm.clickCustomerCard(customer.id, customer);
        }
      }
    }, [_c('update-history', {
      attrs: {
        "type": "information",
        "data": customer
      }
    })], 1);
  }), _vm.customers.length ? _c('v-col', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: _vm.customerLazyLoad,
      expression: "customerLazyLoad"
    }],
    attrs: {
      "cols": "auto"
    }
  }) : _vm._e()], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    attrs: {
      "rounded": "",
      "small": "",
      "text": "",
      "disabled": _vm.customerMaxReached
    },
    on: {
      "mousedown": function mousedown($event) {
        return _vm.swipeRight('customer');
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-arrow-right ")])], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "history-label text-heading-2 font-weight-bold mt-8",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 最近登録・更新されたタスク ")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    attrs: {
      "rounded": "",
      "small": "",
      "text": ""
    },
    on: {
      "mousedown": function mousedown($event) {
        return _vm.swipeLeft('task');
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-arrow-left ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_vm.taskLoading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }) : _vm._e()], 1), _c('v-row', {
    ref: "contentTask",
    staticClass: "flex-nowrap overflow-x-hidden",
    attrs: {
      "id": "contentTask"
    }
  }, [_vm._l(_vm.tasks, function (task, index) {
    return _c('v-col', {
      key: "history-item-".concat(index),
      attrs: {
        "cols": "auto"
      },
      on: {
        "click": function click($event) {
          return _vm.clickTaskCard(task.id);
        }
      }
    }, [_c('update-history', {
      attrs: {
        "type": "task",
        "data": task
      }
    })], 1);
  }), _vm.tasks.length ? _c('v-col', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: _vm.taskLazyLoad,
      expression: "taskLazyLoad"
    }],
    attrs: {
      "cols": "auto"
    }
  }) : _vm._e()], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    attrs: {
      "rounded": "",
      "small": "",
      "text": "",
      "disabled": _vm.taskMaxReached
    },
    on: {
      "mousedown": function mousedown($event) {
        return _vm.swipeRight('task');
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-arrow-right ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }