<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12" class="history-label text-heading-2 font-weight-bold">
        最近登録・更新された物件
      </v-col>
      <!--      <v-col cols="1" class="text-center">-->
      <!--        <v-btn icon fab>-->
      <!--          <v-icon>mdi-chevron-left</v-icon>-->
      <!--        </v-btn>-->
      <!--      </v-col>-->
      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-col cols="1">
            <v-btn rounded small text @mousedown="swipeLeft('customer')">
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="10">
            <div class="mb-4">
              <v-progress-linear indeterminate v-if="customerLoading" />
            </div>
            <v-row id="contentCustomer" ref="contentCustomer" class="flex-nowrap overflow-x-hidden">
              <v-col @click="clickCustomerCard(customer.id, customer)" cols="auto"
                v-for="(customer, index) in customers" :key="`history-item-${index}`">
                <update-history type="information" :data="customer"></update-history>
              </v-col>

              <v-col v-if="customers.length" cols="auto" v-intersect="customerLazyLoad"></v-col>
            </v-row>
          </v-col>
          <v-col cols="1">
            <v-btn rounded small text @mousedown="swipeRight('customer')" :disabled="customerMaxReached">
              <v-icon>
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="history-label text-heading-2 font-weight-bold mt-8">
        最近登録・更新されたタスク
      </v-col>

      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-col cols="1">
            <v-btn rounded small text @mousedown="swipeLeft('task')">
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="10">
            <div class="mb-4">
              <v-progress-linear indeterminate v-if="taskLoading" />
            </div>
            <v-row id="contentTask" ref="contentTask" class="flex-nowrap overflow-x-hidden">
              <v-col @click="clickTaskCard(task.id)" cols="auto" v-for="(task, index) in tasks"
                :key="`history-item-${index}`">
                <update-history type="task" :data="task"></update-history>
              </v-col>

              <v-col v-if="tasks.length" cols="auto" v-intersect="taskLazyLoad"></v-col>
            </v-row>
          </v-col>
          <v-col cols="1">
            <v-btn rounded small text @mousedown="swipeRight('task')" :disabled="taskMaxReached">
              <v-icon>
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <!--      <v-col cols="1" class="text-center">-->
      <!--        <v-btn icon fab>-->
      <!--          <v-icon>mdi-chevron-right</v-icon>-->
      <!--        </v-btn>-->
      <!--      </v-col>-->
    </v-row>
  </v-container>
</template>

<script>
import UpdateHistory from '@/components/admin/partials/Customer/View/Basic/UpdateHistory/UpdateHistory'
export default {
  name: 'UpdateList',
  components: { UpdateHistory },
  props: {
    customers: {
      type: Array,
      required: true
    },
    tasks: {
      type: Array,
      required: true
    },
    customerLoading: {
      type: Boolean,
      default: false,
      required: false
    },
    taskLoading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      history: 20,
      scrollValue: 500,
      scrollDuration: 300,
      lazyLoadThreshold: 400,
      taskMaxReached: false,
      customerMaxReached: false
    }
  },
  watch: {
    customers() {
      this.customerMaxReached = false
    },
    tasks() {
      this.taskMaxReached = false
    }
  },
  methods: {
    clickCustomerCard(value, customer) {
      if (customer.type == "Site") {
        this.$router.push({
          name: 'CustomerViewDetailBasic',
          params: {
            customer_id: customer.customer.id,
            site_id: value
          }
        })
      } else {
        this.$router.push({
          name: 'CustomerViewDetailBasic',
          params: {
            customer_id: customer.id,
            site_id: customer.first_site.id
          }
        })
      }
      // const site_id = customer?.sites[0].id
      // this.$router.push({
      //   name: 'CustomerViewDetailBasic',
      //   params: {
      //     customer_id: customer.id,
      //     site_id: site_id
      //   }
      // })
    },
    clickTaskCard(value) {
      this.$router.push('/task/view/' + value)
    },
    scrollTo(element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft
      if (
        !(
          (scrollPos === 0 || scrollPixels > 0) &&
          (element.clientWidth + scrollPos === element.scrollWidth ||
            scrollPixels < 0)
        )
      ) {
        const startTime =
          'now' in window.performance ? performance.now() : new Date().getTime()

        function scroll(timestamp) {
          const timeElapsed = timestamp - startTime
          const progress = Math.min(timeElapsed / duration, 1)
          element.scrollLeft = scrollPos + scrollPixels * progress
          if (timeElapsed < duration) {
            window.requestAnimationFrame(scroll)
          } else {
            return
          }
        }
        window.requestAnimationFrame(scroll)
      }
    },
    swipeLeft(part) {
      let content = null
      if (part == 'customer') {
        content = this.$refs.contentCustomer
      } else {
        content = this.$refs.contentTask
      }
      this.scrollTo(content, -this.scrollValue, 300)
    },
    swipeRight(part) {
      let content = null
      if (part == 'customer') {
        content = this.$refs.contentCustomer
      } else {
        content = this.$refs.contentTask
      }
      this.scrollTo(content, this.scrollValue, 300)
    },
    taskLazyLoad(entries) {
      if (entries[0].isIntersecting) {
        this.taskMaxReached = true
        this.$emit('load:update-list', 'task')
      }
    },
    customerLazyLoad(entries) {
      if (entries[0].isIntersecting) {
        this.customerMaxReached = true
        this.$emit('load:update-list', 'customer')
      }
    }
  }
}
</script>

<style lang="scss" src="./UpdateList.scss" scoped>
</style>
