<template>
  <div>
    <v-data-table
      :headers="headers"
      :header-props="headerProps"
      :items="items"
      hide-default-header
      :hide-default-footer="!footer"
      v-bind="$attrs"
      v-on="$listeners"
      :footer-props="{
        'items-per-page-options': [10, 20, 25, 50],
        'items-per-page-text': 'Rows per page'
      }"
      :items-per-page="itemsPerPage"
      :pageCount="numberOfPages"
      :server-items-length="totalRecords"
      @click:row="handleClick"
      :disable-pagination="disablePagination"
      :page.sync="page"
      :loading="loading"
      :no-data-text="'表示するデータがありません。'"
      no-results-text="現在読み込み中、少々おまちください。"
      loading-text="現在読み込み中、少々おまちください。"
    >
      <template v-slot:header="{ props, on }">
        <thead class="v-data-table-header">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.name"
              role="columnheader"
              :aria-label="header.text"
              :class="[
                getSortableClass(header.sortable),
                getAlignClass(header.align)
              ]"
              @click="
                totalRecords > 1 && header.sortable !== false
                  ? on.sort(header.value)
                  : ''
              "
              :style="header.width ? 'width: ' + header.width + 'px' : ''"
            >
              <span>{{ header.text }}</span>
              <div v-if="header.sortable !== false" class="sort-icon-container">
                <v-icon
                  class="sort-icon asc"
                  :class="
                    props.options.sortDesc[0] &&
                      props.options.sortBy[0] === header.value &&
                      'selected'
                  "
                  size="16"
                >
                  mdi-chevron-up
                </v-icon>
                <v-icon
                  class="sort-icon desc"
                  :class="
                    !props.options.sortDesc[0] &&
                      props.options.sortBy[0] === header.value &&
                      'selected'
                  "
                  size="16"
                >
                  mdi-chevron-down
                </v-icon>
              </div>
            </th>
          </tr>
        </thead>
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>
    </v-data-table>

    <v-pagination
      v-if="!disablePagination"
      class="custom-pagination mt-4"
      circle
      v-model="page"
      :length="numberOfPages"
      @input="$emit('pagination:update', page)"
      @next="$emit('pagination:next', page)"
      @previous="$emit('pagination:previous', page)"
    ></v-pagination>
  </div>
</template>

<script>
export default {
  name: 'Table',
  props: {
    footer: {
      type: Boolean,
      default: true,
      required: false
    },
    headers: {
      type: Array,
      default: () => [],
      required: true
    },
    headerProps: {
      type: Object,
      default: () => {},
      required: false
    },
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    search: {
      type: String,
      default: '',
      required: false
    },
    numberOfPages: {
      type: Number,
      default: 0,
      required: false
    },
    totalRecords: {
      type: Number,
      default: 0,
      required: false
    },
    disablePagination: {
      type: Boolean,
      default: false,
      required: false
    },
    itemsPerPage: {
      type: Number,
      default: 10,
      required: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      page: 1
    }
  },
  methods: {
    handleClick(value) {
      this.$emit('row-clicked', value)
    },
    getSortableClass(sortable) {
      return sortable !== false ? 'sortable' : ''
    },
    getAlignClass(align) {
      return align !== null ? 'text-' + align : 'text-start'
    }
  }
}
</script>

<style src="./Table.scss" lang="scss" scoped></style>
