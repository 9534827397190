var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-data-table', _vm._g(_vm._b({
    attrs: {
      "headers": _vm.headers,
      "header-props": _vm.headerProps,
      "items": _vm.items,
      "hide-default-header": "",
      "hide-default-footer": !_vm.footer,
      "footer-props": {
        'items-per-page-options': [10, 20, 25, 50],
        'items-per-page-text': 'Rows per page'
      },
      "items-per-page": _vm.itemsPerPage,
      "pageCount": _vm.numberOfPages,
      "server-items-length": _vm.totalRecords,
      "disable-pagination": _vm.disablePagination,
      "page": _vm.page,
      "loading": _vm.loading,
      "no-data-text": '表示するデータがありません。',
      "no-results-text": "現在読み込み中、少々おまちください。",
      "loading-text": "現在読み込み中、少々おまちください。"
    },
    on: {
      "click:row": _vm.handleClick,
      "update:page": function updatePage($event) {
        _vm.page = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(_ref) {
        var props = _ref.props,
            on = _ref.on;
        return [_c('thead', {
          staticClass: "v-data-table-header"
        }, [_c('tr', _vm._l(props.headers, function (header) {
          return _c('th', {
            key: header.name,
            class: [_vm.getSortableClass(header.sortable), _vm.getAlignClass(header.align)],
            style: header.width ? 'width: ' + header.width + 'px' : '',
            attrs: {
              "role": "columnheader",
              "aria-label": header.text
            },
            on: {
              "click": function click($event) {
                _vm.totalRecords > 1 && header.sortable !== false ? on.sort(header.value) : '';
              }
            }
          }, [_c('span', [_vm._v(_vm._s(header.text))]), header.sortable !== false ? _c('div', {
            staticClass: "sort-icon-container"
          }, [_c('v-icon', {
            staticClass: "sort-icon asc",
            class: props.options.sortDesc[0] && props.options.sortBy[0] === header.value && 'selected',
            attrs: {
              "size": "16"
            }
          }, [_vm._v(" mdi-chevron-up ")]), _c('v-icon', {
            staticClass: "sort-icon desc",
            class: !props.options.sortDesc[0] && props.options.sortBy[0] === header.value && 'selected',
            attrs: {
              "size": "16"
            }
          }, [_vm._v(" mdi-chevron-down ")])], 1) : _vm._e()]);
        }), 0)])];
      }
    }, _vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(scope) {
          return [_vm._t(slot, null, null, scope)];
        }
      };
    })], null, true)
  }, 'v-data-table', _vm.$attrs, false), _vm.$listeners)), !_vm.disablePagination ? _c('v-pagination', {
    staticClass: "custom-pagination mt-4",
    attrs: {
      "circle": "",
      "length": _vm.numberOfPages
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('pagination:update', _vm.page);
      },
      "next": function next($event) {
        return _vm.$emit('pagination:next', _vm.page);
      },
      "previous": function previous($event) {
        return _vm.$emit('pagination:previous', _vm.page);
      }
    },
    model: {
      value: _vm.page,
      callback: function callback($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }