var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-text', [_c('v-container', [_c('v-row', {
    staticClass: "py-4"
  }, [_c('v-col', {
    staticClass: "text-center text-heading-1",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" クレーム管理 "), _c('div', [_c('v-btn', {
    staticClass: "close-button",
    attrs: {
      "depressed": "",
      "fab": "",
      "color": "#E27C7C",
      "width": "20",
      "height": "20"
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "14"
    }
  }, [_vm._v("mdi-close")])], 1)], 1)])], 1), _c('div', {
    staticClass: "claim-form"
  }, [_c('validation-observer', {
    ref: "observer"
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.save.apply(null, arguments);
      }
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-header mb-2"
  }, [_vm._v("タスク")]), _c('div', [_c('validation-provider', {
    attrs: {
      "name": "tasks",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "outlined": "",
            "placeholder": "task id / 日付 / サービス名 ",
            "hide-details": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "items": _vm.tasks,
            "item-value": "value"
          },
          model: {
            value: _vm.data.task_id,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "task_id", $$v);
            },
            expression: "data.task_id"
          }
        })];
      }
    }])
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-header mb-2"
  }, [_vm._v("クレーム分類")]), _c('div', [_c('validation-provider', {
    attrs: {
      "name": "クレーム分類",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          staticClass: "form-text",
          attrs: {
            "outlined": "",
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "items": _vm.claimCategories,
            "hide-details": "auto",
            "placeholder": "未選択",
            "chips": "",
            "multiple": "",
            "clearable": ""
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function fn(_ref3) {
              var attrs = _ref3.attrs,
                  item = _ref3.item,
                  select = _ref3.select;
              return [_c('v-chip', _vm._b({
                staticClass: "mb-1",
                attrs: {
                  "color": "#8C5F5F",
                  "text-color": "white",
                  "label": "",
                  "small": "",
                  "close": ""
                },
                on: {
                  "click": select,
                  "click:close": function clickClose($event) {
                    return _vm.removeTag(item.value);
                  }
                }
              }, 'v-chip', attrs, false), [_c('span', [_vm._v(_vm._s(item.text))])])];
            }
          }], null, true),
          model: {
            value: _vm.data.claim_category_id,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "claim_category_id", $$v);
            },
            expression: "data.claim_category_id"
          }
        })];
      }
    }])
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-header mb-2"
  }, [_vm._v("クレーム内容")]), _c('div', [_c('validation-provider', {
    attrs: {
      "name": "des",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-textarea', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "outlined": "",
            "placeholder": "クレームの内容を箇条書きにしてください",
            "hide-details": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "maxlength": _vm.descriptionMaxLength
          },
          on: {
            "keyup": function keyup($event) {
              return _vm.desCount();
            }
          },
          model: {
            value: _vm.data.description,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "description", $$v);
            },
            expression: "data.description"
          }
        })];
      }
    }])
  }), _c('div', {
    staticClass: "text-right mt-1"
  }, [_vm._v(" " + _vm._s(_vm.totalDescharacter ? _vm.totalDescharacter : _vm.data.description.length) + " / " + _vm._s(_vm.descriptionMaxLength) + " 文字 ")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-header mb-2"
  }, [_vm._v("対応内容")]), _c('div', [_c('validation-provider', {
    attrs: {
      "name": "res",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-textarea', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "outlined": "",
            "placeholder": "どのように対応したかを記入してください",
            "hide-details": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "maxlength": _vm.responseMaxLength
          },
          on: {
            "keyup": function keyup($event) {
              return _vm.resCount();
            }
          },
          model: {
            value: _vm.data.response,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "response", $$v);
            },
            expression: "data.response"
          }
        })];
      }
    }])
  }), _c('div', {
    staticClass: "text-right mt-1"
  }, [_vm._v(" " + _vm._s(_vm.totalRescharacter ? _vm.totalRescharacter : _vm.data.response.length) + " / " + _vm._s(_vm.responseMaxLength) + " 文字 ")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [this.claimItem && this.claimItem.id ? _c('v-btn', {
    attrs: {
      "color": "#AD4545",
      "text": ""
    },
    on: {
      "click": _vm.deleteTask
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("$trash")]), _vm._v("削除する")], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "#4F55A7",
      "width": "100",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("登録 ")])], 1)], 1)], 1)], 1)], 1)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }