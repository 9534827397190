<template>
  <v-container :class="`card-${type}`">
    <v-row no-gutters class="text-center">
      <v-col cols="12" v-if="type === 'task'" class="task-date py-1">
        <v-icon color="#2F80ED" size="20" class="pb-1">$workTime</v-icon>
        {{ data.updated_at | formatDate }}
      </v-col>
      <v-col cols="12" class="company-name py-1">
        {{ type === 'task' ? data.customer.name : data.name }}
      </v-col>

      <template v-if="type === 'information'">
        <template v-if="data.type == 'Site'">
          <v-col cols="12" class="site-name font-weight-bold py-1">
            {{ data.site_id }} {{ data.name }}
          </v-col>
          <v-col cols="12" class="py-1">
            <v-icon size="16">$workTime</v-icon>
            {{ data.updated_at | formatDate }}
          </v-col>
          <v-col cols="12" class="py-1">
            <v-icon size="14">$user</v-icon>
            {{ data.updated_by_user ? data.updated_by_user.last_name : '-' }}
          </v-col>
        </template>

        <template v-if="data.type == 'Customer'">
          <!-- <v-col cols="12" class="site-name font-weight-bold py-1">
            {{ data.site_id }} {{ data.name }}
          </v-col> -->
          <v-col cols="12" class="py-1">
            <v-icon size="16">$workTime</v-icon>
            {{ data.updated_at | formatDate }}
          </v-col>
          <v-col cols="12" class="py-1">
            <v-icon size="14">$user</v-icon>
            {{ data.updated_by_user ? data.updated_by_user.last_name : '-' }}
          </v-col>
        </template>



        <!-- <v-col cols="12" class="site-name font-weight-bold py-1">
          {{ data.sites[0] ? data.sites[0].site_id : '' }} {{ data.sites[0] ? data.sites[0].name : '' }}gf
        </v-col>
        <v-col cols="12" class="py-1">
          <v-icon size="16">$workTime</v-icon>
          {{ data.updated_at | formatDate }}
        </v-col>
        <v-col cols="12" class="py-1">
          <v-icon size="14">$user</v-icon>
          {{ data.updated_by_user ? data.updated_by_user.last_name : '-' }}
        </v-col> -->
      </template>

      <template v-else>
        <v-col cols="12" class="py-1">
          {{ data.site.site_id }} {{ data.site.name }}
        </v-col>
        <v-col cols="12" class="py-1">
          {{ data.service ? data.service.name : '-' }}
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'UpdateHistory',
  props: {
    type: {
      type: String,
      default: '',
      required: true
    },
    data: {
      required: false
    }
  },
  filters: {
    formatDate(value) {
      if (value) {
        return dayjs(value).format('YYYY/MM/DD HH:mm')
      }
    }
  }
}
</script>

<style lang="scss" src="./UpdateHistory.scss" scoped>
</style>
