<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row class="py-4">
          <v-col cols="12" class="text-center text-heading-1">
            クレーム管理
            <div>
              <v-btn
                depressed
                class="close-button"
                fab
                color="#E27C7C"
                width="20"
                height="20"
                @click="closeDialog"
              >
                <v-icon size="14">mdi-close</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <div class="claim-form">
          <validation-observer ref="observer">
            <form @submit.prevent="save">
              <v-col cols="12">
                <div class="form-header mb-2">タスク</div>
                <div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="tasks"
                    rules="required"
                  >
                    <v-select
                      class="form-text"
                      dense
                      outlined
                      placeholder="task id / 日付 / サービス名 "
                      hide-details
                      v-model="data.task_id"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                      :items="tasks"
                      item-value="value"
                    ></v-select>
                  </validation-provider>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="form-header mb-2">クレーム分類</div>
                <div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="クレーム分類"
                    rules="required"
                  >
                    <v-select
                      class="form-text"
                      outlined
                      dense
                      :error-messages="errors"
                      :error="errors.length !== 0"
                      :items="claimCategories"
                      hide-details="auto"
                      placeholder="未選択"
                      chips
                      multiple
                      clearable
                      v-model="data.claim_category_id"
                    >
                      <template v-slot:selection="{ attrs, item, select }">
                        <v-chip
                          class="mb-1"
                          color="#8C5F5F"
                          text-color="white"
                          label
                          small
                          close
                          v-bind="attrs"
                          @click="select"
                          @click:close="removeTag(item.value)"
                        >
                          <span>{{ item.text }}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </validation-provider>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="form-header mb-2">クレーム内容</div>
                <div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="des"
                    rules="required"
                  >
                    <v-textarea
                      class="form-text"
                      dense
                      outlined
                      placeholder="クレームの内容を箇条書きにしてください"
                      hide-details
                      v-model="data.description"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                      @keyup="desCount()"
                      :maxlength="descriptionMaxLength"
                    ></v-textarea>
                  </validation-provider>
                  <div class="text-right mt-1">
                    {{
                      totalDescharacter
                        ? totalDescharacter
                        : data.description.length
                    }}
                    / {{ descriptionMaxLength }} 文字
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="form-header mb-2">対応内容</div>
                <div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="res"
                    rules="required"
                  >
                    <v-textarea
                      class="form-text"
                      dense
                      outlined
                      placeholder="どのように対応したかを記入してください"
                      hide-details
                      :error-messages="errors"
                      :error="errors.length !== 0"
                      v-model="data.response"
                      @keyup="resCount()"
                      :maxlength="responseMaxLength"
                    ></v-textarea>
                  </validation-provider>
                  <div class="text-right mt-1">
                    {{
                      totalRescharacter
                        ? totalRescharacter
                        : data.response.length
                    }}
                    / {{ responseMaxLength }} 文字
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <v-row align="center">
                  <v-col cols="6">
                    <v-btn
                      v-if="this.claimItem && this.claimItem.id"
                      @click="deleteTask"
                      color="#AD4545"
                      text
                    >
                      <v-icon left>$trash</v-icon>削除する</v-btn
                    >
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-col cols="auto">
                      <v-btn
                        @click="save"
                        color="#4F55A7"
                        class="white--text"
                        width="100"
                        :loading="loading"
                        >登録
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-row>
              </v-col>
            </form>
          </validation-observer>
        </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Dialog',
  props: {
    customerId: {
      type: [String, Number],
      default: '',
      required: true
    },
    claimItem: {
      type: Object,
      default: () => {},
      required: false
    },
    customer: {
      type: Object,
      required: true
    },
    siteId: {
      type: [String, Number],
      required: true
    },
    projectId: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    ...mapGetters(['user', 'customerTasksById', 'allClaimCategories']),
    tasks() {
      let tasklist = []
      this.customerTasksById.map(task => {
        tasklist.push({
          value: task.id,
          text: `${task.id} / ${task.date} / ${task?.service?.name}`
        })
      })

      return tasklist.sort((a, b) => b.value - a.value)
    },
    claimCategories() {
      return this.allClaimCategories.map(ClaimCategories => {
        return { value: ClaimCategories.id, text: ClaimCategories.name }
      })
    }
  },
  watch: {
    claimItem: function(val) {
      this.data.description = ''
      this.data.response = ''
      this.data.task_id = ''
      this.data.claim_category_id = ''
      this.data.created_by = ''
      this.data.updated_by = ''
      this.patchEditFormValues()
    }
  },
  created() {
    // this.getDataFromApi()
    this.patchEditFormValues()
  },
  methods: {
    ...mapMutations(['showModal']),
    getDataFromApi() {
      this.$store.dispatch('CLAIM_CATEGORY_GET_ALL').then(() => {
        this.loading = false
      })
    },
    closeDialog() {
      this.$emit('close-dialog')
      this.$refs.observer.reset()
    },
    patchEditFormValues() {
      if (this.claimItem && this.claimItem.id) {
        this.data.description = this.claimItem.description
        this.data.response = this.claimItem.response
        this.data.task_id = this.claimItem.task_id
        this.data.claim_category_id = this.claimItem.categories?.map(category => category.id)
        ;(this.data.customer_id = this.customerId),
          (this.data.created_by = this.user.id)
        this.data.updated_by = this.user.id
      } else {
        this.data.description = ''
        this.data.response = ''
        this.data.task_id = ''
        this.data.claim_category_id = ''
        this.data.customer_id = this.customerId
        this.data.created_by = this.user.id
        this.data.updated_by = this.user.id
      }
    },
    save() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        this.loading = true
        if (this.claimItem && this.claimItem.id) {
          let data = {}
          data.claim_id = this.claimItem.id
          data.description = this.data.description
          data.response = this.data.response
          data.task_id = this.data.task_id
          data.claim_category_id = this.data.claim_category_id
          data.customer_id = this.customerId
          data.created_by = this.user.id
          data.updated_by = this.user.id

          this.$store.dispatch('CUSTOMER_EDIT_CLAIM', data).then(
            response => {
              if (response.data.status == 'success') {
                let param = {
                  claim: {
                    customer_id: this.customerId,
                    site_id: this.siteId,
                    project_id: this.projectId,
                    paginate: 10
                  }
                }
                this.$store.dispatch('CUSTOMER_GET_ALL_CLAIM', param.claim)
                this.$store.dispatch('CUSTOMER_GET', this.customerId)
                this.$refs.observer.reset()
                this.loading = false
                this.closeDialog()
              }
            },
            error => {
              this.loading = false
              throw error
            }
          )
        } else {
          this.$store.dispatch('CUSTOMER_CREATE_CLAIM', this.data).then(
            response => {
              if (response.data.status == 'success') {
                let param = {
                  claim: {
                    customer_id: this.customerId,
                    site_id: this.siteId,
                    project_id: this.projectId,
                    paginate: 10
                  }
                }
                this.$store.dispatch('CUSTOMER_GET_ALL_CLAIM', param.claim)
                this.$store.dispatch('CUSTOMER_GET', this.customerId)
                this.$refs.observer.reset()
                this.loading = false
                this.closeDialog()
              }
            },
            error => {
              this.loading = false
              throw error
            }
          )
        }
      })
    },
    deleteTask() {
      this.showModal({
        text: 'このアクションを実施してよろしいですか',
        action: {
          process: {
            color: 'red',
            text: '実施する'
          },
          cancel: {
            color: 'secondary',
            text: 'キャンセル'
          }
        },
        func: this.processDelete
      })
    },
    processDelete() {
      if (this.claimItem && this.claimItem.id) {
        let claim_id = this.claimItem.id
        let data = this.claimItem.id
        this.$store.dispatch('CUSTOMER_DELETE_CLAIM', { claim_id }).then(
          response => {
            if (response.data.status == 'success') {
              let param = {
                claim: {
                  customer_id: this.customerId,
                  site_id: this.siteId,
                  paginate: 10
                }
              }
              this.$store.dispatch('CUSTOMER_GET_ALL_CLAIM', param.claim)
              this.$store.dispatch('CUSTOMER_GET', this.customerId)
              this.closeDialog()
            }
          },
          error => {
            throw error
          }
        )
      }
    },
    removeClaimCat(item) {
      this.data.claim_category_id = this.data.claim_category_id.filter(
        claimCategories => claimCategories !== item
      )
    },
    desCount: function() {
      this.totalDescharacter = this.data.description.length
    },
    resCount: function() {
      this.totalRescharacter = this.data.response.length
    },
    removeTag(item) {
      this.data.claim_category_id = this.data.claim_category_id.filter(
        claimCategories => claimCategories !== item
      )
    }
  },
  data() {
    return {
      choosenPhoto: null,
      descriptionMaxLength: 1000,
      responseMaxLength: 1000,
      data: {
        description: '',
        response: '',
        task_id: '',
        claim_category_id: [],
        customer_id: '',
        created_by: '',
        updated_by: '',
        claim_id: ''
      },
      totalDescharacter: 0,
      totalRescharacter: 0,
      loading: false
    }
  }
}
</script>

<style lang="scss" src="./Dialog.scss" scoped></style>
