var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    class: "card-".concat(_vm.type)
  }, [_c('v-row', {
    staticClass: "text-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_vm.type === 'task' ? _c('v-col', {
    staticClass: "task-date py-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-icon', {
    staticClass: "pb-1",
    attrs: {
      "color": "#2F80ED",
      "size": "20"
    }
  }, [_vm._v("$workTime")]), _vm._v(" " + _vm._s(_vm._f("formatDate")(_vm.data.updated_at)) + " ")], 1) : _vm._e(), _c('v-col', {
    staticClass: "company-name py-1",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.type === 'task' ? _vm.data.customer.name : _vm.data.name) + " ")]), _vm.type === 'information' ? [_vm.data.type == 'Site' ? [_c('v-col', {
    staticClass: "site-name font-weight-bold py-1",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.site_id) + " " + _vm._s(_vm.data.name) + " ")]), _c('v-col', {
    staticClass: "py-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "16"
    }
  }, [_vm._v("$workTime")]), _vm._v(" " + _vm._s(_vm._f("formatDate")(_vm.data.updated_at)) + " ")], 1), _c('v-col', {
    staticClass: "py-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "14"
    }
  }, [_vm._v("$user")]), _vm._v(" " + _vm._s(_vm.data.updated_by_user ? _vm.data.updated_by_user.last_name : '-') + " ")], 1)] : _vm._e(), _vm.data.type == 'Customer' ? [_c('v-col', {
    staticClass: "py-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "16"
    }
  }, [_vm._v("$workTime")]), _vm._v(" " + _vm._s(_vm._f("formatDate")(_vm.data.updated_at)) + " ")], 1), _c('v-col', {
    staticClass: "py-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "14"
    }
  }, [_vm._v("$user")]), _vm._v(" " + _vm._s(_vm.data.updated_by_user ? _vm.data.updated_by_user.last_name : '-') + " ")], 1)] : _vm._e()] : [_c('v-col', {
    staticClass: "py-1",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.site.site_id) + " " + _vm._s(_vm.data.site.name) + " ")]), _c('v-col', {
    staticClass: "py-1",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.service ? _vm.data.service.name : '-') + " ")])]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }